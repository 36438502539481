<!--
 * @Author: lbh
 * @Date: 2023-03-15 15:55:05
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 10:39:55
 * @Description: file content
-->
<template>
  <div class="edit-textSuspendedOnPicture-box px-edit-textSuspendedOnPicture-box">
    <div class="cell-b">
      <selfCell title="上傳圖片">
        <selfUpload
          v-model="configs.image"
          type="default"
          @change="setValue('image')"
        />
      </selfCell>
      <el-input
        v-model="configs.alt"
        type="textarea"
        rows="3"
        @change="setValue('alt')"
        placeholder="請輸入圖片描述"
      />
    </div>
    <div class="cell-b">
      <selfCell
        class="cell-b"
        title="標題"
      >
        <div>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
            @change="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
            :placeholder="`請輸入-${lItem.name}`"
            type="textarea"
            rows="3"
          />
        </div>
      </selfCell>
      <div class="cell-b">
        <h4>副標題
          <selfTips :content="$tipText.textWrap" />
        </h4>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}subTitle`]"
          type="textarea"
          rows="8"
          @change="setValue(`${lItem.code=='HK'?'':lItem.code}subTitle`)"
          :placeholder="`請輸入-${lItem.name}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          image: '',
          alt: "",
          title: '',
          subTitle: "",
        }
      }
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    },
  }
}
</script>

<style lang="less" scoped>
</style>